.footer {
  width: 100%;
  min-height: 420px;
  background: rgba(19, 18, 18, 1);
  padding: 0 0 20px 0;
}
.footer-s {
  width: 100%;
  min-height: 420px;
  background: rgba(19, 18, 18, 1);
  margin: 450px 0 0 0;
  padding: 0 0 20px 0;
}

.footer_a {
  font-size: 14px;
  font-weight: 400;
  color: rgba(226, 226, 226, 1);
  text-decoration: none;
  margin: 0 0 10px 0;
}
.with_prosent {
  margin: 0 auto;
}
.clas-footer {
  width: 20px;
  height: 20px;
}
.clas-footer img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.as_bas {
  text-decoration: underline;
}
.media_block {
  display: none;
}
.search {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_border {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-bottom: 2px solid rgba(0, 0, 0, 1);
}
.interview_one_higth {
  width: 100%;
  margin: 0 0 100px 0;
}
.img_deteails {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: center;
  margin: 10px 0 0 0;
}
.img_deteils_all_imgage {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}
.line-high {
  margin: 0 0 8px 0;
  line-height: 1;
  width: 80%;
}
.deteils_title_el {
  margin: 60px 0 20px 0;
}
.description_class {
  margin: 60px 0 0 0 !important;
}
.description_class p {
  margin: 0 0 20px 0 !important;
}
.description_class p h1 {
  font-size: 20px;
  font-weight: 700;
  color: #131212;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

.cntnt_img {
  object-fit: cover;
  object-position: center;
}
.image-higth {
  width: 100%;
}
.burder {
  width: 36px;
  height: 2px;
  background: rgba(19, 18, 18, 1);
  cursor: pointer;
}
.burder_one {
  width: 36px;
  height: 2px;
  background: rgba(19, 18, 18, 1);
  cursor: pointer;
  margin: 6px 0 0 0;
}

.burder_two {
  width: 36px;
  height: 2px;
  background: rgba(19, 18, 18, 1);
  cursor: pointer;
  margin: 6px 0 0 0;
}
.burger_block_menu {
  display: none;
}

.burger_block_wight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(40px);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.burger_menu_active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(40px);
  z-index: 999;
  opacity: 1;
  transition: opacity 0.5s ease;
  pointer-events: auto;
}
.burder_wiate {
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.burger_menu_wiate {
  width: 65%;
  height: 100%;
  background: #ffffff;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.5s ease;
  pointer-events: auto;
}
.git_img_two {
  width: 100%;
  height: 260px;
  object-fit: cover;
  object-position: center;
  margin: 0 0 10px 0;
}
.center_burger {
  display: flex;
  flex-direction: column;
}
.to_hover_link_active {
  display: flex;
  align-items: center;
  justify-content: left !important;
  margin: 0 40px 0 0;
}

.contacts_flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 40px 0 100px 0;
}
.contacts_flex .img {
  width: 100%;
  height: 540px;
  object-fit: cover;
  object-position: center;
}
.contacts {
  width: 100%;
  min-height: 540px;
  background: rgba(19, 18, 18, 1);
  padding: 40px;
  padding: 30px 30px 0 30px;
}
.contacts_project {
  font-size: 14px;
  color: rgba(151, 151, 151, 1);
  font-weight: 400;
}
.contacts a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin: 0 0 10px 0;
}
.contacts_flex_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.contacts_time {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.images_contact img {
  width: 50px;
  height: 50px;
}
.fec {
  width: 30px !important;
  height: 25px !important;
}
.text-contant {
  margin: 0 0 40px 0 !important;
}
.margin_top {
  margin: 40px 0 0 0;
}
.deteils-lfex {
  display: flex;
  align-items: center;
}
.i18n button {
  padding: 5px 8px;
  border: none;
  margin: 0 10px 0 0;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: bold;
}
.i18n button:hover {
  padding: 5px 8px;
  background: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: bold;
}

.activeKG {
  padding: 5px 8px;
  background: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  border: none;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: bold;
  color: #fff;
}

.rewiev {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.rewiev_input {
  padding: 0 0 0 20px;
  height: 45px;
  margin: 0 0 20px 0;
  outline: none;
  border: 1px solid rgba(151, 151, 151, 1);
  color: #131212;
  font-size: 16px;
  font-weight: 400;
  width: 70%;
}
.rewiev_input:focus {
  border: 2px solid transparent;
  border-image: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  border-image-slice: 1;
}

.rewiev_input::placeholder {
  color: rgba(151, 151, 151, 1);
  font-size: 16px;
  font-weight: 400;
}
.rewiev_textarea {
  padding: 10px 0 0 20px;
  height: 100px;
  margin: 0 0 20px 0;
  outline: none;
  border: 1px solid rgba(151, 151, 151, 1);
  color: #131212;
  font-size: 16px;
  font-weight: 400;
  width: 70%;
}
.rewiev_textarea:focus {
  border: 2px solid transparent;
  border-image: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  border-image-slice: 1;
}
.btn_rewiev {
  background: linear-gradient(90.95deg, #af802a -0.52%, #dfc464 126.88%);
  border: none;
  width: 140px;
  height: 45px;
  padding: 10px, 24px, 10px, 24px;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
.rewiev_p {
  font-size: 18px !important;
  text-align: center;
}
.notice {
  width: 70%;
  background: #fcfcfc;
  padding: 15px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 0;
}
.rewiev_img {
  width: 36px;
  height: 36px;
}
.rewiev_h6 {
  color: rgba(19, 18, 18, 1);
  font-size: 16px;
  font-weight: 600;
}
.rewiev_span_one {
  color: rgba(52, 52, 52, 1);
  font-size: 14px;
  font-weight: 400;
}
.us {
  font-size: 24px;
  color: rgba(19, 18, 18, 1);
  text-align: center;
  font-weight: 700;
  line-height: 1;
}
.img_banner {
  width: 100%;
  height: 540px;
  object-fit: cover;
  object-position: center;
}
.p_mir p {
  color: rgba(19, 18, 18, 1);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  padding: 20px;
}
.modal_comment {
  display: none;
  transition: 2s;
}
.modal_comment_one {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 2s;
}
.modal_comment_text {
  margin: 0 auto;
  max-width: 620px;
  position: relative;
  box-sizing: border-box;
  padding: 20px 35px 20px 20px;
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  z-index: 1002;
  background-color: #fff;
  font-weight: 400;
}

.slider_gallery_detail .img_slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: center;
}

.slider_gallery_detail .slider_img img {
  padding: 0 5px;
  height: 110px;
  object-fit: cover;
  object-position: center;
}

.tags {
  text-decoration: none;
  padding: 8px 30px;
  margin: 0 10px 0 0;
  color: rgba(120, 120, 120, 1);
  background: rgba(245, 247, 250, 1);
  font-size: 14px;
  font-weight: 500;
}

.slider-container .slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 30px 0 0 0;
  list-style: none;
}
.slider-container .slick-dots li {
  position: relative;
  display: inline-block;
  width: 13%;
  height: 100%;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.banner_url_img {
  width: 120px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}

.slider_img:hover .slider_hover_block {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.slider_modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider_modal div {
  width: 90%;
  margin: 0 auto;
}

.slider_modal .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10%;
  height: 100%;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.click_daown:hover {
  color: #0576e7;
}
.created_at {
  font-weight: 400;
  font-size: 12px;
  color: rgba(120, 120, 120, 1);
}

.popular-block-back {
  width: 100%;
  padding: 25px 30px;
  background: rgba(255, 255, 255, 1);
  margin: 40px 0 0 0;
}

.baner-img {
  width: 100%;
  height: 152px;
  object-fit: cover;
  object-position: center;
}

.class_baner {
  margin: 40px 0;
}
