@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Jost;
  src: url("../fonts/static/Jost-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Georgia;
  src: url("../fonts/georgia/Georgia\ Regular\ font.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Georgia;
  src: url("../fonts/georgia/Georgia-Bold.ttf");
  font-weight: 700;
}

body {
  background: #f3f3f3 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding-right: calc(var(--bs-gutter-x) * 1) !important;
  padding-left: calc(var(--bs-gutter-x) * 1) !important;
}

.containers {
  width: 100%;
  max-width: 1272px;
  margin: 0 auto;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
  font-family: Jost;
}
p {
  font-family: Jost;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.banner-mobile {
  display: none !important;
}

.withe_bg {
  background: #f3f3f3 !important;
  width: 100%;
  height: 100%;
}
.link_text {
  font-size: 14px;
  color: #131212;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

.create {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: #ffffff;
}

.project {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  color: rgba(255, 255, 255, 1);
}
.create_span {
  text-transform: uppercase;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: #131212;
  text-transform: uppercase;
}

.popular_text {
  border: none;
  outline: none;
  padding: 5px 20px;
  background: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.popular_title {
  font-size: 16px;
  font-weight: 700;
  color: rgba(19, 18, 18, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.black-text {
  color: rgba(255, 255, 255, 1);
}
.popular_project {
  font-size: 14px;
  font-weight: 400;
  color: rgba(151, 151, 151, 1);
  text-transform: uppercase;
}

.cntnt_hldr {
  width: fit-content;
}
.one_text {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background: linear-gradient(90.95deg, #b0852c -0.52%, #d8c05b 126.88%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.one_title {
  color: rgba(19, 18, 18, 1);
  margin: 0 0;
}
.onrow {
  margin: 0 0 10px 0;
}

.view {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
}
.black_bg_one h5 {
  color: rgba(19, 18, 18, 1);
}
.black_bg_one p {
  color: rgba(19, 18, 18, 1);
}
.black_bg_one h6 {
  color: rgba(19, 18, 18, 1);
}
.inputs {
  border: none;
  outline: none;
  width: 450px;
  margin: 0 10px 0 10px;
}
.size_text {
  font-size: 22px;
}
.large {
  font-size: 14px;
  font-weight: 500;
}
.deteil_title {
  font-size: 20px;
  font-weight: 700;
  color: #131212;
  margin: 60px 0 0 0;
}
.pro_deteil {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
